import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

import { addEmail } from "../store/slices/applicationFormSlice";
import regex from "../utils/regex";

export default function Email({ email, customerId }) {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(email?.address);

  const handleAddEmail = () => {
    let isValid = true
    isValid = regex.email.test(address)

    if (address && email?.address !== address && isValid) {
      dispatch(
        addEmail({
          emailId: email?._id,
          customerId,
          address: address.replace(/\s+/g, '')
        })
      );
    }
  };

  const handleEmailValue = (event) => {
    const value = event.target?.value
    value === "" ? setAddress("") :  setAddress(value);
  };

  useEffect(() => {
    // console.log("email", email);
  }, []);

  return (
    <>
      <TextField
        error={!address}
        helperText={!address ? "Required" : ""}
        id="outlined-basic"
        type="text"
        name="applicant_phoneNumber"
        label="Email"
        variant="outlined"
        value={address || ""}
        onChange={handleEmailValue}
        onBlur={() => handleAddEmail()}
        inputProps={{
          autoComplete: "none"
        }}
      />
    </>
  );
}
