import { useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// redux
import { userSelector, validateToken } from "./store/slices/userSlice";
import { organisationSelector } from "./store/slices/organisationSlice";
// layouts
import PreAuthWrapper from "./containers/PreAuthWrapper";
import AuthWrapper from "./containers/AuthWrapper";
import AuthWrapperNoMenu from "./containers/AuthWrapperNoMenu";
// import NotFound from './pages/Page404';
// pages
import Application from "./pages/Application";
import ApplicationsList from "./pages/ApplicationsList";
import Organisation from "./pages/Register/Organisation";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";
import CustomerList from "./pages/CustomerList";
import AcceptRequestToJoin from "./pages/AcceptRequestToJoin";
import InviteUsers from "./pages/Register/InviteUsers";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import UserProfile from "./components/UserProfile";
import UserSettings from "./components/UserSettings";
import OrganisationProfile from "./components/OrganisationProfile";
import Billing from "./components/Billing";
import Subscribe from "./components/Subscribe";
import Payment from "./components/Payment";
import Privacy from "./pages/Privacy";

// ----------------------------------------------------------------------

function handleDecode(token) {
  if (token && token.length > 12) {
    const now = Date.now() / 1000;
    const decoded = jwt_decode(token);
    console.log("decoded", decoded.exp);
    // console.log("now", now);

    return decoded.exp < now;
  }

  return true;
}

export default function Router(props) {
  const token = localStorage.token;
  const isTokenExpired = handleDecode(token);
  // const isTokenExpired = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = localStorage.user;

  async function handleValidateToken(t) {
    if (t) await dispatch(validateToken(t)).unwrap();
  }

  function noToken() {
    if (isTokenExpired) return <PreAuthWrapper {...props} />;
  }

  useEffect(() => {
      handleValidateToken(token);
  }, []);

  return useRoutes([
    {
      path: "/",
      element: noToken(),
      children: [
        { path: "register", element: <Register /> },
        { path: "login", element: <Login /> },
        { path: "privacy", element: <Privacy /> },
        // { path: "forgotpassword", element: <Password /> },
        // { path: "reset-password/:resetToken", element: <UpdatePassword /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/login" /> },
      ],
    },
    {
      path: "/setup",
      element: !isTokenExpired ? (
        <AuthWrapperNoMenu {...props} />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "organisation", element: <Organisation /> },
        { path: "invite-users", element: <InviteUsers /> },
      ],
    },
    {
      path: "/password",
      element: <PreAuthWrapper {...props} />,
      children: [
        { path: "forgot", element: <ForgotPassword /> },
        { path: "reset/:key", element: <ResetPassword user={user} /> },
      ],
    },
    {
      path: "/dashboard",
      element: !isTokenExpired ? (
        <AuthWrapper {...props} />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "applications", element: <ApplicationsList /> },
        { path: "application/", element: <Application /> },
        { path: "application/:applicationId", element: <Application /> },
        { path: "customers", element: <CustomerList /> },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
      ],
    },
    {
      path: "/account",
      element: !isTokenExpired ? (
        <AuthWrapper {...props} />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "profile", element: <UserProfile /> },
        { path: "organisation", element: <OrganisationProfile /> },
        { path: "settings", element: <UserSettings /> },
        { path: "billing", element: <Billing /> },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
      ],
    },
    {
      path: "/request-success",
      element: <AcceptRequestToJoin />,
    },
    {
      path: "/request-fail",
      element: <AcceptRequestToJoin />,
    },
    {
      path: "/subscribe",
      element: !isTokenExpired ? (
        <AuthWrapper {...props} />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "start", element: <Subscribe /> },
        {
          path: "payment-details",
          element: <Payment />,
        },
        // { path: "success", element: <UserSettings /> },
        // { path: "cancelled", element: <Billing /> },

        // {
        //   path: "/dashboard",
        //   element: (
        //     <Navigate to={`/dashboard`} />
        //   ),
        // },
      ],
    },
    // {
    //   path: "/setup",
    //   element: <Navigate to="/" />,
    // },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
