import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAltTwoTone";
import PersonIcon from "@mui/icons-material/PersonTwoTone";
import RequestQuoteIcon from "@mui/icons-material/RequestQuoteTwoTone";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material/styles";


import Entity from "../components/Entity";
import CustomerDetails from "../components/CustomerDetails";
import ScrollNav from "../components/ScrollNav";
import Products from "../components/ProductSelector";
import Notes from "../components/Notes";
import {
  saveValue,
  saveAsset,
  startApplication,
  getApplication,
  updateAsset,
  updateLoanDetails,
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  saveLoanDetails,
  userDetailsSelector,
  createCustomer,
  getApplicationDownloadUrl,
  submitApplication,
  clearState,
  uploadInvoice,
  removeCustomer,
  updateDeclaration
} from "../store/slices/applicationFormSlice";

import products from "../products";
import { assetTypes } from "../assetTypes.json";

import getBalloon from "src/utils/getBalloon";
import calculateRepayments from "src/utils/calculateRepayments";
import { evaluateCriteria } from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";

import generateArrayOfYears from "src/utils/generateArrayOfYears";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  ButtonGroup,
  ClickAwayListener,
  FormLabel,
  Grow,
  IconButton,
  InputBase,
  MenuList,
  Paper,
  Radio,
  RadioGroup,
  Popper,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  Card,
  CardContent,
} from "@mui/material";
import _ from "lodash";
import Product from "src/components/Product";
import ProductSelector from "../components/ProductSelector";

// import { typeOfSaleList } from "src/constants";
import { ArrowDropDownCircleRounded, ArrowDropDownOutlined, AttachMoney } from "@mui/icons-material";

const dollarStringOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
}

export default function Application({ socket }) {
  const dispatch = useDispatch();
  const { applicationId } = useParams();

  // console.log(applicationId);

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const [confirmRemoveCustomer, setConfirmRemoveCustomer] = useState({});

  const [uploadInvoiceButtonBackground, setUploadInvoiceButtonBackground] =
    useState("secondary");
  const [uploadInvoiceText, setUploadInvoiceButtonText] =
    useState("Upload invoice");
  // const applicationId = localStorage.getItem("applicationId");
  const { uploadInvoiceLoading, url, loadingSubmitApplication, linkSent, gotASICData, submitSuccess } =
    useSelector(userDetailsSelector);
  const application = useSelector(applicationSelector);
  // console.log(application);
  const asset = useSelector(assetSelector);
  const declaration = application?.declaration

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    make = "",
    model = "",
    description = "",
    supplierName = "",
    supplierABN = "",
    supplierAddress = "",
    supplierContactName = "",
    supplierPhone = "",
    supplierEmail = "",
    privateSale = false,
    typeOfSale = "",
    // documentFee: 320,
  } = useSelector(assetSelector);

  const loanDetails = useSelector(loanDetailsSelector);

  const {
    // applicationFee = 0,
    balloon = 0,
    term = 0,
    // repayments = 0,
    // loanAmount = 0,
    brokerage = 0,
    // rate = 0,
    deposit = 0,
  } = useSelector(loanDetailsSelector);

  // const socketEmit = (event, data) => {
  //   socket.emit(event, {
  //     applicationId,
  //     data,
  //   });
  // };

  // console.log("brokerage", brokerage);
  // console.log("deposit", deposit);

  const [brokerageValue, setBrokerageValue] = useState(brokerage);
  const [depositValue, setDepositValue] = useState(deposit);
  const [balloonValue, setBalloonValue] = useState(balloon);
  const [termValue, setTermValue] = useState(term);
  const [assetTypeValue, setAssetTypeValue] = useState(assetType);

  const [supplierNameValue, setSupplierNameValue] = useState(supplierName);
  const [supplierABNValue, setSupplierABNValue] = useState(supplierABN);
  const [supplierAddressValue, setSupplierAddressValue] = useState(supplierAddress);
  const [supplierContactNameValue, setSupplierContactNameValue] = useState(supplierContactName);
  const [supplierPhoneValue, setSupplierPhoneValue] = useState(supplierPhone);
  const [supplierEmailValue, setSupplierEmailValue] = useState(supplierEmail);

  const [declarationOne, setDeclarationOne] = useState(declaration?.one);
  const [declarationTwo, setDeclarationTwo] = useState(declaration?.two);
  const [declarationThree, setDeclarationThree] = useState(declaration?.three);

  const [isApplicationComplete, setIsApplicationComplete] = useState(false);

  const [maxBalloon, setMaxBallon] = useState(30);
  const [balloonMarks, setBalloonMarks] = useState([
    { value: 0, label: 0 },
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
  ]);

  function handleApplicationComplete(isComplete) {
    console.log({ isComplete })
    setIsApplicationComplete(isComplete)
  }

  const handleAgeOfAsset = (event, newValue) => {
    console.log(newValue);
    const termInYears = term / 12;
    // console.log("termInYears", termInYears)
    const ageOfAssetMoment = moment(newValue, "YYYY");
    const endOfTermYear = moment().add(termInYears, "year");
    // console.log(ageOfAssetMoment);
    // console.log(endOfTermYear);
    // console.log(endOfTermYear.diff(ageOfAssetMoment, "years"));
    const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
    // console.log("ageOfAssetAtEnd", ageOfAssetAtEnd);
    if (newValue < moment().subtract(2, "years").format("YYYY")) handleBalloon(0);
    dispatch(saveAsset({ ageOfAsset: newValue, ageOfAssetAtEnd }));
    const update = _.merge({
      ...asset,
      ...{ ageOfAsset: newValue, ageOfAssetAtEnd },
    });
    // console.log(update);
    dispatch(updateAsset(update));
  };

  const handleAssetType = (event) => {
    setAssetTypeValue(event.target.value);
    // dispatch(saveAsset({ assetType: event.target.value }));
    const update = _.merge({ ...asset, ...{ assetType: event.target.value } });
    console.log(update);
    dispatch(updateAsset(update));
    // updateQuote();
  };

  const handleMake = (event) => {
    // console.log(event.target.value);

    dispatch(saveAsset({ make: event.target.value }));
    // updateQuote();
  };

  const handleModel = (event) => {
    // console.log(event.target.value);

    dispatch(saveAsset({ model: event.target.value }));
    // updateQuote();
  };

  const handleDescription = (event) => {
    // console.log(event.target.value);

    dispatch(saveAsset({ description: event.target.value }));
    // updateQuote();
  };

  const handleSellerType = (event) => {
    console.log(event.target.value);

    // dispatch(saveAsset({ privateSale: event.target.checked }));
    console.log(asset);
    const update = _.merge({
      ...asset,
      ...{ typeOfSale: event.target.value },
    });
    console.log(update);
    dispatch(updateAsset(update));
    // updateQuote();
  };

  const handleSupplier = (event) => {
    const update = _.merge({ ...asset, ...{ [event.target.name]: event.target.value } });
    console.log(update);
    dispatch(updateAsset(update));
    // updateQuote();
  };

  const handleBalloon = async (value) => {
    setBalloonValue(value);
    dispatch(saveLoanDetails({ balloon: value }));
    // updateQuote();
    dispatch(
      updateLoanDetails(
        _.merge({
          ...loanDetails,
          ...{
            balloon: value,
          },
        })
      )
    );
  };

  const handleTerm = (value) => {
    console.log("event.target.value", value);
    setTermValue(value);
    handleMaxBalloon(value)
    const termInYears = value / 12;
    const ageOfAssetMoment = moment(ageOfAsset, "YYYY");
    const endOfTermYear = moment().add(termInYears, "year");
    console.log(ageOfAssetMoment);
    console.log(endOfTermYear);
    // console.log(endOfTermYear.diff(ageOfAssetMoment, "years"));
    const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
    console.log("ageOfAssetAtEnd", ageOfAssetAtEnd);
    dispatch(saveAsset({ ageOfAssetAtEnd }));
    const update = _.merge({
      ...asset,
      ...{ ageOfAssetAtEnd },
    });
    console.log(update);
    dispatch(updateAsset(update));
    dispatch(saveLoanDetails({ term: value }));
    dispatch(
      updateLoanDetails(
        _.merge({
          ...loanDetails,
          ...{
            term: value,
          },
        })
      )
    );
    // dispatch(saveLoanDetails({ term: value }));
    // updateQuote();
  };

  function handleMaxBalloon(TERM) {
    if (TERM === 48) {
      setBalloonMarks([
        { value: 0, label: 0 },
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 },
        { value: 40, label: 40 },
      ])
      return setMaxBallon(40)
    }
    if (TERM === 36) {
      setBalloonMarks([
        { value: 0, label: 0 },
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 },
        { value: 40, label: 40 },
        { value: 50, label: 50 },
      ])
      return setMaxBallon(50)
    }
    if (TERM === 24) {
      setBalloonMarks([
        { value: 0, label: 0 },
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 },
        { value: 40, label: 40 },
        { value: 50, label: 50 },
        { value: 60, label: 60 },
      ])
      return setMaxBallon(60)
    }
    if (TERM === 12) {
      setBalloonMarks([
        { value: 0, label: 0 },
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 },
        { value: 40, label: 40 },
        { value: 50, label: 50 },
        { value: 60, label: 60 },
        { value: 70, label: 70 },
      ])
      return setMaxBallon(70)
    }

    setBalloonMarks([
      { value: 0, label: 0 },
      { value: 10, label: 10 },
      { value: 20, label: 20 },
      { value: 30, label: 30 },
    ])

    return setMaxBallon(30)
  }

  function handleBalloonMarks() {

  }

  const handleDeposit = (event) => {
    setDepositValue(event.target.value * 1);
  };

  // const handleRepayments = async (value) =>
  //   await handleUpdateLoanDetails({ repayments: toNumber(value) });

  // const handleRate = (rate) => handleUpdateLoanDetails({ rate });

  const handleAssetValue = async (event) => {
    // let value = (event.target.value * 1).toFixed(2);
    // if (value > 45000) value = 45000;
    // console.log("assetValue", value);
    dispatch(saveAsset({ assetValue: event.target.value }));
  };

  function handleBrokerageValue(event) {
    // const value = parseInt(event.target.value);
    // console.log("event.target.value", event.target.value);
    setBrokerageValue(event.target.value * 1);
  }

  const calculateMaxBrokerage = (value) => {
    if (value > 35000) return value * 0.08;
    if (value > 45000) return value * 0.06;

    return value * 0.1;
  };

  const handleBrokerage = (value) => {
    if (brokerageValue || brokerageValue === 0) {
      // console.log("brokerage", brokerageValue);
      const ASSET_VALUE = value || assetValue;
      // console.log("assetValue", ASSET_VALUE);
      const maxBrokerage = calculateMaxBrokerage(ASSET_VALUE - depositValue);
      // console.log("maxBrokerage", maxBrokerage);
      const calculateBrokeraged = calculateBrokerage(maxBrokerage);
      // console.log("calculatedBrokerage", calculateBrokerage);

      setBrokerageValue(calculateBrokeraged * 1);
      // dispatch(saveLoanDetails({ brokerage: calculateBrokeraged * 1 }));

      return calculateBrokeraged;
    }
  };

  function calculateBrokerage(maxBrokerage) {
    // console.log(brokerageValue);
    if (!brokerageValue || brokerageValue === 0) return 0;

    if (brokerageValue > maxBrokerage) return maxBrokerage;

    return brokerageValue;
  }

  const handleUpdateAssetDetails = () => {
    console.log(asset);
    if (asset._id) dispatch(updateAsset(asset));
  };

  function handleUpdateLoanDetails(
    balloonParam,
    termParam,
    deposit
  ) {
    if (loanDetails._id)
      dispatch(
        updateLoanDetails(
          _.merge({
            ...loanDetails,
            ...{
              // loanAmount: total,
              // rate: matchedProductTier[0]?.rate,
              // repayments: roundedRepayments * 1,
              // brokerage: calcBrokerage,
              deposit,
              balloon: balloonParam || balloonValue,
              term: termParam || termValue,
            },
          })
        )
      );
  }

  async function handleApplicationLoad() {
    console.log("applicationId", applicationId);
    // console.log("application", application);
    // if (!application._id) {
    // if (!applicationId) {
    // return await dispatch(startApplication()).unwrap();
    // }
    // console.log("applicationId", applicationId);
    if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
    // updateQuote();
  }

  function handleAddCustomer() {
    dispatch(
      createCustomer({
        applicationId: application._id,
        entities: application?.entities,
      })
    );
  }

  function handleUploadInvoice(event) {
    dispatch(saveValue({ uploadInvoiceLoading: true }));
    const formData = new FormData();
    console.log(event.target.files[0]);
    // Update the formData object
    formData.append("file", event.target.files[0]);

    // Details of the uploaded file
    console.log(formData);

    dispatch(uploadInvoice({ formData, assetId: asset._id }));

    // Request made to the backend api
    // Send formData object
  }

  function handleUploadInvoiceButtonSuccessStyle() {
    setUploadInvoiceButtonBackground("success");
    setUploadInvoiceButtonText("Invoice successfully uploaded");

    setTimeout(() => {
      setUploadInvoiceButtonBackground("secondary");
      setUploadInvoiceButtonText("Upload invoice");
    }, 4000);
  }

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [depositUnit, setDepositUnit] = useState("percent");

  const depositOptions = [<span>%</span>, <AttachMoney style={{ color: "rgba(0, 0, 0, 0.7)" }} />];

  const handleClick = () => {
    console.info(`You clicked ${depositOptions[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    console.log(index)
    setSelectedIndex(index);
    setOpen(false);
    return index === 1 ? setDepositUnit("dollar") : setDepositUnit("percent")
  };



  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    console.log(declaration?.one)
    setDeclarationOne(declaration?.one);
  }, [declaration?.one]);

  useEffect(() => {
    setDeclarationTwo(declaration?.two);
  }, [declaration]);

  useEffect(() => {
    setDeclarationThree(declaration?.three);
  }, [declaration]);

  useEffect(() => {
    setSupplierNameValue(supplierName);
  }, [supplierName]);

  useEffect(() => {
    setSupplierABNValue(supplierABN);
  }, [supplierABN]);

  useEffect(() => {
    setSupplierAddressValue(supplierAddress);
  }, [supplierAddress]);

  useEffect(() => {
    setSupplierContactNameValue(supplierContactName);
  }, [supplierContactName]);

  useEffect(() => {
    setSupplierPhoneValue(supplierPhone);
  }, [supplierPhone]);

  useEffect(() => {
    setSupplierEmailValue(supplierEmail);
  }, [supplierEmail]);

  useEffect(() => {
    setBrokerageValue(brokerage);
  }, [brokerage]);

  useEffect(() => {
    setDepositValue(deposit);
  }, [deposit]);

  useEffect(() => {
    setBalloonValue(balloon);
  }, [balloon]);

  useEffect(() => {
    setTermValue(term);
  }, [term]);

  useEffect(() => {
    setAssetTypeValue(assetType);
  }, [assetType]);

  useEffect(() => {
    setDeclarationOne(declaration?.one);
    setDeclarationTwo(declaration?.two);
    setDeclarationThree(declaration?.three);
  }, [declaration?.one]);

  useEffect(() => {
    handleApplicationLoad();

    return function cleanUp() {
      dispatch(clearState());
    };
  }, [applicationId]);

  useEffect(() => {
    if (uploadInvoiceLoading === false) handleUploadInvoiceButtonSuccessStyle();
  }, [uploadInvoiceLoading]);

  return (
    <>
      {/* <Container> */}
      <Grid
        container
        item
        style={{ marginTop: "64px", padding: "30px 0px 100px" }}
      >

        <Grid container item sm={3} md={3} lg={3} style={{ padding: "0 20px" }}>
          <Notes handleApplicationComplete={handleApplicationComplete} />
        </Grid>

        <Grid container item sm={6} md={6} lg={6} style={{ padding: "0 20px" }}>
          <Box>
            <Accordion
              defaultExpanded
              id="assetDetails"
              elevation={1}
              style={{ padding: "0 15px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <DirectionsCarIcon style={{ marginRight: "15px" }} />
                <Typography>Asset Details</Typography>
              </AccordionSummary>

              <AccordionDetails style={{ paddingBottom: "40px" }}>
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12}>
                    <TextField
                      fullWidth
                      style={{ background: "#ffffff" }}
                      // variant="filled"
                      type="number"
                      name="assetvalue"
                      label="Asset value"
                      // size="small"
                      error={!assetValue}
                      helperText={!assetValue ? "Required" : ""}
                      value={assetValue}
                      onChange={handleAssetValue}
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      onBlur={async () => {
                        // handleAssetValueMinMax();
                        handleUpdateAssetDetails();
                      }}
                    />
                  </Grid>

                  <Grid item md={8} sm={12}>
                    <TextField
                      fullWidth
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // variant="filled"
                      style={{ background: "#ffffff" }}
                      select
                      error={!assetTypeValue}
                      helperText={!assetTypeValue ? "Required" : ""}
                      value={assetTypeValue || ""}
                      label="Asset type"
                      // size="small"

                      onChange={(event) => {
                        handleAssetType(event);
                        // updateQuote();
                        // socketEmit("updateAsset", {
                        //   assetType: event.target.value,
                        // });
                      }}
                    >
                      {assetTypes.map((asset, i) => (
                        <MenuItem value={asset.label}>{asset.label}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>


                  {/* <Grid item md={6} className="d_none"> */}
                  {/* <span></span> */}
                  {/* </Grid> */}

                  <Grid item md={4} sm={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="make"
                      label="Make"
                      value={make || ""}
                      error={!make}
                      helperText={!make ? "Required" : ""}
                      onChange={(event) => handleMake(event)}
                      inputProps={{
                        autoComplete: "none",
                        disableUnderline: true,
                      }}
                      onBlur={() => {
                        dispatch(updateAsset(asset))
                      }}
                    // variant="filled"
                    // variant="outlined"
                    // size="small"
                    />
                  </Grid>

                  <Grid item md={8} sm={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="model"
                      label="Model"
                      value={model || ""}
                      error={!model}
                      helperText={!model ? "Required" : ""}
                      onChange={(event) => handleModel(event)}
                      onBlur={handleUpdateAssetDetails}
                      // variant="outlined"
                      // variant="filled"
                      // size="small"
                      inputProps={{
                        disableUnderline: true,
                        autoComplete: "none",
                      }}
                    />
                  </Grid>

                  <Grid item md={4} sm={12}>
                    <Autocomplete
                      value={ageOfAsset?.toString() || ""}
                      options={generateArrayOfYears()}
                      onChange={handleAgeOfAsset}

                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          // size="small"
                          {...params}
                          label="Year of manufacture"
                        // style={{ padding: 0 }}
                        // variant="outlined"
                        // variant="filled"
                        // InputProps={{ disableUnderline: true }}
                        />
                      )}
                    // onBlur={() => socketEmit("updateAsset", { ageOfAsset })}
                    />
                  </Grid>
                  <Grid item md={8} sm={12}>
                    {/* <Typography id="input-slider" variant="body1">
                      Type of sale
                    </Typography> */}

                    {/* <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        color="secondary"
                        defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={handleSellerType}
                        checked={privateSale}
                      />
                      <Typography>Yes</Typography>
                    </Stack> */}

                    <TextField
                      fullWidth
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // variant="filled"
                      style={{ background: "#ffffff" }}
                      select
                      value={typeOfSale || ""}
                      error={!typeOfSale}
                      helperText={!typeOfSale ? "Required" : ""}
                      label="Type of sale"
                      // size="small"

                      onChange={handleSellerType}
                    >
                      {[
                        { label: "Accredited dealer", default: true },
                        { label: "Private sale" },
                        { label: "Mid-term refinance" },
                        { label: "Balloon | Residual Refinance" },
                        { label: "Sale & buyback | Equity" },
                      ].map((asset, i) => (
                        <MenuItem default={asset.default} value={asset.label}>
                          {asset.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* <Grid item md={9} sm={8}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="description"
                      label="Description"
                      // variant="filled"
                      value={description || ""}
                      onChange={(event) => handleDescription(event)}
                      onBlur={() => dispatch(updateAsset({ asset }))}
                      InputProps={{ disableUnderline: true }}
                      // variant="outlined"
                      // size="small"
                    />
                  </Grid> */}

                  {/* <Grid item md={12} sm={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <label htmlFor="contained-button-file">
                        <Input
                          accept="*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(event) => {
                            handleUploadInvoice(event);
                            event.target.value = null;
                          }}
                        />
                        <LoadingButton
                          loading={uploadInvoiceLoading}
                          variant="contained"
                          component="span"
                          color={uploadInvoiceButtonBackground}
                        >
                          {uploadInvoiceText}
                        </LoadingButton>
                      </label>
                    </Stack>
                  </Grid> */}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              defaultExpanded
              id="loanDetails"
              elevation={1}
              style={{ padding: "0 15px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <RequestQuoteIcon style={{ marginRight: "15px" }} />
                <Typography>Loan Details</Typography>
              </AccordionSummary>

              <AccordionDetails style={{ paddingBottom: "40px" }}>
                <Grid container spacing={3}>
                  <Grid item sm={2}>
                    {/* <Stack direction="row"> */}
                    <TextField
                      fullWidth
                      // id="outlined-basic"
                      // type="number"
                      name="depositamount"
                      label="Deposit amount"
                      // variant="filled"
                      // variant="outlined"
                      // size="small"
                      // min=0
                      // max={10}
                      value={depositValue}
                      onChange={handleDeposit}
                      onBlur={() => {
                        console.log(depositValue)
                        // if (depositValue || depositValue !== 0) {
                        // if (depositUnit === "dollar") {
                        //   return handleUpdateLoanDetails(null, null, depositValue * 1 / assetValue)
                        // }
                        console.log(depositValue)
                        handleUpdateLoanDetails(null, null, depositValue * 1)
                        // }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      style={{ background: "#fff" }}
                    // InputProps={{ style: { borderRight: 0 } }}

                    />
                    {/* <>
                        <ButtonGroup ref={anchorRef} aria-label="split button" style={{ marginLeft: "-3px", }}>
                          <Button onClick={handleClick} style={{ borderColor: "rgba(0, 0, 0, 0.23)", background: "#fff", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>{depositOptions[selectedIndex]}</Button>
                          <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            style={{ borderColor: "rgba(0, 0, 0, 0.23)", borderLeft: 0 }}
                          >
                            <ArrowDropDownOutlined style={{ color: "rgba(0,0,0,0.7)" }} />
                          </Button>
                        </ButtonGroup>
                        <Popper
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          placement="bottom-start"
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom' ? 'center top' : 'center bottom',
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList id="split-button-menu" autoFocusItem style={{ zIndex: 1 }} >
                                    {depositOptions.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        disabled={index === 2}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                        style={{ zIndex: 1 }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </> */}
                    {/* </Stack> */}

                  </Grid>

                  {/* <Grid item sm={1}></Grid> */}

                  <Grid item sm={5} style={{ padding: "16px 18px" }}>
                    {/* <FormControl fullWidth  > */}
                    <Typography variant="subtitle2">Balloon</Typography>
                    <Box style={{ padding: "0 7px" }}>
                      <Slider
                        color="secondary"
                        marks={balloonMarks}
                        disabled={
                          ageOfAsset <
                          moment().subtract(2, "years").format("YYYY")
                        }
                        step={5}
                        min={0}
                        max={maxBalloon}
                        value={balloonValue}
                        onChange={(event) => {
                          console.log("balloon", event.target.value)
                          setBalloonValue(event.target.value)
                          // updateQuote(event.target.value);
                        }}
                        onChangeCommitted={() => handleBalloon(balloonValue)}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box>
                    {ageOfAsset <
                      moment().subtract(2, "years").format("YYYY") && (
                        <label style={{ fontSize: "12px" }}>
                          Only on vehicles &#60;2 years old
                        </label>
                      )}
                    {/* </FormControl> */}
                  </Grid>
                  {/* <Grid item>
                    <TextField
                    size="small"
                      value={balloon}
                      margin="dense"
                      onChange={handleBalloon}
                      // onBlur={() =>
                      //   socketEmit("updateLoanDetails", { balloon })
                      // }
                      inputProps={{
                        step: 5,
                        min: 0,
                        max: 40,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid> */}
                  <Grid item sm={5} style={{ padding: "16px 18px" }}>
                    {/* <FormControl fullWidth> */}
                    <Typography variant="subtitle2">
                      Repayment term (in months)
                    </Typography>
                    <Box style={{ padding: "0 7px" }}>
                      <Slider
                        color="secondary"
                        marks={[
                          { value: 12, label: 12 },
                          { value: 24, label: 24 },
                          { value: 36, label: 36 },
                          { value: 48, label: 48 },
                          { value: 60, label: 60 },
                        ]}
                        step={12}
                        min={12}
                        max={60}
                        value={termValue}
                        onChange={(event) => {
                          setTermValue(event.target.value)
                          // updateQuote(undefined, event.target.value);
                        }}
                        onChangeCommitted={() => handleTerm(termValue)}
                        defaultValue={60}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box>
                    {/* </FormControl> */}
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}></Grid> */}
                <Grid container spacing={3}>
                  {/* <Grid item sm={5}>
                    <TextField
                      fullWidth
                      // size="small"
                      // id="outlined-basic"
                      // variant="filled"
                      // type="number"
                      name="brokerage"
                      label="Brokerage"
                      // variant="outlined"
                      value={brokerageValue}
                      onChange={handleBrokerageValue}
                      onBlur={() => {
                        handleBrokerage();
                        updateQuote();
                      }}
                    />
                  </Grid> */}
                  {/* <Grid item sm={1}></Grid> */}
                  {/* <Grid item md={4} sm={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className="field_style"
                    >
                      <TextField
                        id="outlined-basic"
                        type="number"
                        name="documentfee"
                        label="Document fee"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item>
                    <TextField
                    size="small"
                      value={term}
                      margin="dense"
                      onChange={handleTerm}
                      // onBlur={() => socketEmit("updateLoanDetails", { term })}
                      inputProps={{
                        step: 12,
                        min: 0,
                        max: 60,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid> */}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Entity application={application} />

            <Accordion
              defaultExpanded
              id="declaration"
              elevation={1}
              style={{ padding: "0 15px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                Declaration
              </AccordionSummary>

              <AccordionDetails style={{ paddingBottom: "40px" }}>
                <Stack direction="column">
                  <FormControl style={{ marginBottom: "20px" }}>
                    <FormLabel id="demo-radio-buttons-group-label-one">Will the goods be used for rideshare, courier or hired/rented to a third party?</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label-one"
                      // defaultValue="No"
                      value={declarationOne || ""}
                      onChange={(event) => {
                        setDeclarationOne(event.target.value)
                        dispatch(updateDeclaration({ _id: declaration._id, one: event.target.value }))
                      }}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>

                  <FormControl style={{ marginBottom: "20px" }}>
                    <FormLabel id="demo-radio-buttons-group-label-two">Does the client have current ATO arrears or a payment arrangement in place?</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label-two"
                      // defaultValue="No"
                      value={declarationTwo || ""}
                      onChange={(event) => {

                        setDeclarationTwo(event.target.value)
                        dispatch(updateDeclaration({ _id: declaration._id, two: event.target.value }))
                      }}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>

                  <FormControl style={{ marginBottom: "20px" }}>
                    <FormLabel id="demo-radio-buttons-group-label-three">Does the client have any adverse credit?</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label-three"
                      // defaultValue="No"
                      value={declarationThree || ""}
                      onChange={(event) => {
                        setDeclarationThree(event.target.value)
                        dispatch(updateDeclaration({ _id: declaration._id, three: event.target.value }))
                      }}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </AccordionDetails>

            </Accordion>

            {application?.customers?.length > 0 &&
              application?.customers?.map((customer, i) => (
                <Accordion
                  defaultExpanded
                  id={`applicant-${customer.firstName}-${customer.lastName}`}
                  elevation={1}
                  style={{ padding: "0 15px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <PersonIcon style={{ marginRight: "15px" }} />
                    <Typography>
                      {customer.firstName || customer.lastName
                        ? `${customer.firstName} ${customer.lastName}`
                        : "[New guarantor]"}
                    </Typography>
                    {!confirmRemoveCustomer[customer._id] && (
                      <Button
                        size="small"
                        style={{
                          padding: 0,
                          marginLeft: "20px",
                          // color: "#555",
                        }}
                        onClick={() =>
                          setConfirmRemoveCustomer({ [customer._id]: true })
                        }
                      >
                        Remove
                      </Button>
                    )}
                    {confirmRemoveCustomer[customer._id] && (
                      <Stack direction="row" style={{ marginLeft: "20px" }}>
                        <Typography>Are you sure?</Typography>
                        <Button
                          size="small"
                          style={{
                            padding: 0,
                            marginLeft: "20px",
                            // color: "#1976d2",
                          }}
                          onClick={() =>
                            setConfirmRemoveCustomer({ [customer._id]: false })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          style={{
                            padding: 0,
                            marginLeft: "20px",
                            color: "#d32f2f",
                          }}
                          onClick={() => {
                            dispatch(
                              removeCustomer({
                                customerId: customer._id,
                                applicationId: application._id,
                              })
                            );
                            setConfirmRemoveCustomer({ [customer._id]: false });
                          }}
                        >
                          Remove
                        </Button>
                      </Stack>
                    )}
                  </AccordionSummary>

                  <AccordionDetails style={{ paddingBottom: "40px" }}>
                    <Grid container spacing={3}>
                      <CustomerDetails socket={socket} customer={customer} index={i} />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            <Paper
              id="addGuarantor"
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                padding: "15px 15px 15px 35px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                cursor: "pointer",
              }}
              onClick={handleAddCustomer}
            >
              <PersonAddAltIcon style={{ marginRight: "10px" }} />
              <Typography>Add guarantor</Typography>
            </Paper>

            {!application?.customers?.[0] && (
              <Typography
                variant="caption"
                style={{
                  padding: "15px 15px 15px 0px",
                }}
              >
                <strong>
                  It is recommended to add an entity before adding customers.
                </strong>{" "}
                Guarantors are automatically added to the application from ASIC
                if the entity is a company.
              </Typography>
            )}

            <Accordion
              defaultExpanded
              id="assetDetails"
              elevation={1}
              style={{ padding: "0 15px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <DirectionsCarIcon style={{ marginRight: "15px" }} />
                <Typography>Supplier Details</Typography>
              </AccordionSummary>

              <AccordionDetails style={{ paddingBottom: "40px" }}>
                <Grid container spacing={2}>
                  <Grid item md={7} sm={7}>
                    <TextField
                      fullWidth
                      name="supplierName"
                      style={{ background: "#ffffff" }}
                      // variant="filled"
                      // type="number"
                      label="Supplier name"
                      // size="small"

                      value={supplierNameValue}
                      onChange={(event) => setSupplierNameValue(event.target.value)}
                      onBlur={(event) => handleSupplier(event)}
                    />
                  </Grid>

                  <Grid item md={5} sm={5}>
                    <TextField
                      fullWidth
                      name="supplierABN"
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // variant="filled"
                      style={{ background: "#ffffff" }}
                      value={supplierABNValue}
                      label="ABN"
                      // size="small"
                      onChange={(event) => setSupplierABNValue(event.target.value)}
                      onBlur={(event) => handleSupplier(event)}
                    ></TextField>
                  </Grid>

                  <Grid item md={12} sm={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="supplierAddress"
                      label="Address"
                      value={supplierAddressValue}
                      onChange={(event) => setSupplierAddressValue(event.target.value)}
                      onBlur={(event) => handleSupplier(event)}
                      // variant="outlined"
                      // variant="filled"
                      // size="small"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item md={5} sm={5}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="supplierContactName"
                      label="Contant name"
                      value={supplierContactNameValue}
                      onChange={(event) => setSupplierContactNameValue(event.target.value)}
                      onBlur={(event) => handleSupplier(event)}
                      // variant="outlined"
                      // variant="filled"
                      // size="small"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item md={4} sm={4}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="supplierEmail"
                      label="Email"
                      value={supplierEmailValue}
                      onChange={(event) => {
                        console.log(event.target.name)
                        setSupplierEmailValue(event.target.value)
                      }}
                      onBlur={(event) => handleSupplier(event)}
                      // variant="outlined"
                      // variant="filled"
                      // size="small"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="supplierPhone"
                      label="Phone number"
                      value={supplierPhoneValue}
                      onChange={(event) => {
                        console.log(event.target.value)
                        setSupplierPhoneValue(event.target.value)
                      }}
                      onBlur={(event) => handleSupplier(event)}
                      // variant="outlined"
                      // variant="filled"
                      // size="small"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>

        <Grid conatiner item sm={3} md={3} lg={3} style={{ padding: "0 20px" }}>
          <Box
            style={{
              // background: color.lightPink
              // padding: "20px",
              width: "calc(25vw - 40px)",
              position: "fixed",
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 140px)",
              scrollbarColor: "white rebeccapurple",
              scrollbarWidth: "thin",
              // marginTop: "-55px",
            }}
          >
            <ProductSelector isApplicationComplete={isApplicationComplete} />

            {/* <Grid>
              <Card
                alignItems="flex-start"
                style={{
                  margin: "0 0 10px 0",
                  // background: setBackground()
                }}
              >
                <CardContent>
                  <Typography>Required Fields</Typography>
                  <ul>
                    <li></li>
                  </ul>
                </CardContent>
              </Card>
            </Grid> */}
          </Box>
        </Grid>


        <Snackbar
          open={submitSuccess}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => dispatch(saveValue({ submitSuccess: false }))}
        // message="Application submitted"
        // action={action}
        >
          <Alert
            onClose={() => dispatch(saveValue({ submitSuccess: false }))}
            elevation={6}
            severity="success"
            sx={{ width: "100%" }}
          >
            Application submitted successfully
          </Alert>
        </Snackbar>
        <Snackbar
          open={linkSent}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => dispatch(saveValue({ linkSent: false }))}
        // message="Application submitted"
        // action={action}
        >
          <Alert
            onClose={() => dispatch(saveValue({ linkSent: false }))}
            elevation={6}
            severity="success"
            sx={{ width: "100%" }}
          >
            Link sent
          </Alert>
        </Snackbar>

        <Snackbar
          open={gotASICData}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => dispatch(saveValue({ linkSent: false }))}
        // message="Application submitted"
        // action={action}
        >
          <Alert
            onClose={() => dispatch(saveValue({ linkSent: false }))}
            elevation={6}
            severity="success"
            sx={{ width: "100%" }}
          >
            ASIC Data retrieved
          </Alert>
        </Snackbar>

      </Grid>
      {/* </Container> */}
    </>
  );
}
