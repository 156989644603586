import { useEffect, useState } from "react";
import _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Card,
  CardContent,
  Hidden,
  Collapse,
  CardActions,
  Button,
  TextField,
  CardHeader,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  saveAsset,
  startApplication,
  getApplication,
  updateAsset,
  updateLoanDetails,
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  saveLoanDetails,
  userDetailsSelector,
  createCustomer,
  getApplicationDownloadUrl,
  submitApplication,
  clearState,
  entitySelector,
  customersSelector,
} from "../store/slices/applicationFormSlice";

import products from "../products";

import getBalloon from "src/utils/getBalloon";
import calculateRepayments from "src/utils/calculateRepayments";
import {
  evaluateCriteria,
  // calculateBrokerage,
  calculateFees,
  calculateExtras,
} from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";

import ProductSelectorOrderBy from "./ProductSelectorOrderBy";
import { LoadingButton } from "@mui/lab";

import afsLogo from "../afs-blue.png";
// console.log(products);

function openInNewTab(url) {
  var win = window?.open(url, "_blank");
  win?.focus();
}

const dollarStringOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
}

export default function Product({ productDetails, isApplicationComplete }) {
  // console.log("productDetails", productDetails);

  const {
    balloon = 0,
    term = 0,
    deposit = 0,
    rate,
    fees,
    brokerage,
    loanAmount,
    repayments,
  } = useSelector(loanDetailsSelector);

  // console.log({ loanAmount })

  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const [rateValue, setRateValue] = useState(productDetails?.rate);
  const [feesValue, setFeesValue] = useState(0);
  const [loanAmountValue, setLoanAmountValue] = useState(
    productDetails?.loanAmount
  );

  // console.log({ loanAmountValue })

  const [repaymentsValue, setRepaymentsValue] = useState(
    productDetails?.repayments
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  let { application, url, loadingSubmitApplication } =
    useSelector(userDetailsSelector);

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    privateSale,
    typeOfSale,
  } = useSelector(assetSelector);

  // console.log("brokerage", brokerage);

  const [brokerageValue, setBrokerageValue] = useState(
    productDetails?.brokerage?.value
  );

  const [submitButtonBackground, setSubmitButtonBackground] = useState("primary");
  const [submitButtonText, setSubmitButtonText] = useState("Submit");

  const DEPOSIT = (deposit / 100 * assetValue)

  // console.log({ assetValue }, { ageOfAsset }, { assetType }, { privateSale });

  function handleCriteriaColor(result) {
    if (result) return "#2e7d32";
    if (result === undefined) return "#999999";
    return "#c62828";
  }

  function extrasStyle(result) {
    if (result) return { color: "inherit" };
    if (result === undefined) return { color: "#999999" };
    return { color: "inherit", textDecoration: "line-through" };
  }

  const handleLoanAmount = (ASSETVALUE) => {
    // console.log(assetValue, deposit, brokerage);
    // console.log(assetValue - deposit);

    return (
      ASSETVALUE * 1 -
      DEPOSIT * 1 +
      calculateFees() +
      (brokerageValue / 100) * (ASSETVALUE * 1 - DEPOSIT * 1)
    );
  };

  function handleOriginationFee() {
    const originationFee = productDetails?.fees?.filter(fee => fee.name === "Origination fee")[0]
    // console.log({ originationFee })
    setFeesValue(originationFee?.value)
  }

  function handleFees(originationFeeValue) {
    // console.log({ originationFeeValue })
    return productDetails?.fees?.map(fee => {
      if (fee.name === "Origination fee") {
        fee.value = originationFeeValue || fee.value

        return fee
      }

      return fee
    })
  }

  function calculateFees() {
    const calcFeesList = handleFees()

    return calcFeesList.reduce(
      (accum, item) => item.value + accum,
      0
    );
  }

  const calculateQuote = (loanAmount) => {
    const monthlyRate = getMonthlyRate(rateValue);
    const rpmts = calculateRepayments(
      monthlyRate,
      term,
      -loanAmount,
      (balloon / 100) * (assetValue - DEPOSIT),
      1
    );
    const roundedRepayments = (Math.round(rpmts * 100) / 100).toFixed(2);

    setRepaymentsValue(roundedRepayments * 1);

    return roundedRepayments * 1;
  };

  const updateQuote = () => {
    // console.log("update");
    // if (asset._id) dispatch(updateAsset({ asset }));
    const amount = handleLoanAmount(assetValue);

    setLoanAmountValue(amount);
    const repay = calculateQuote(amount * 1);
  };

  async function saveQuote() {
    // if (loanDetails._id)
    //   console.log({
    //     loanAmount: loanAmountValue,
    //     rate: rateValue,
    //     repayments: repaymentsValue,
    //     brokerage: brokerageValue,
    //     fees: feesValue,
    //   });
    await dispatch(
      updateLoanDetails(
        _.merge({
          ...loanDetails,
          ...{
            loanAmount: loanAmountValue,
            rate: rateValue,
            repayments: repaymentsValue,
            brokerage: brokerageValue,
            fees: handleFees(),
          },
        })
      )
    ).unwrap();
  }

  useEffect(() => {
    if (loanAmountValue && repaymentsValue) updateQuote();
  }, [
    brokerage,
    // assetValue,
    // deposit,
    // assetType,
    // privateSale,
    // ageOfAsset,
    // balloon,
    // term,
    // customers,
    // typeOfSale,
  ]);

  // useEffect(() => {
  //   setRateValue(rate);
  //   setBrokerageValue(brokerage);
  //   setFeesValue(fees);
  // }, [rate, loanAmount, repayments, brokerage, repayments]);

  useEffect(() => {
    setLoanAmountValue(productDetails?.loanAmount);
  }, [productDetails?.loanAmount]);

  useEffect(() => {
    setBrokerageValue(productDetails?.brokerage?.value);
  }, [productDetails?.brokerage?.value]);

  // useEffect(() => {
  //   setFeesValue(fees);
  // }, [productDetails.fees]);

  useEffect(() => {
    setRepaymentsValue(productDetails?.repayments);
  }, [productDetails?.repayments]);

  useEffect(() => {
    setRateValue(productDetails?.rate);
  }, [productDetails?.rate]);

  useEffect(() => {
    handleOriginationFee()
  }, [productDetails?.fees]);

  // useEffect(() => {
  //   const amount = handleLoanAmount(assetValue);

  //   setLoanAmountValue(amount);
  //   calculateQuote(amount * 1);
  // updateQuote();
  // }, []);

  function setHeaderBackground() {
    // console.log(productDetails.fit);
    if (productDetails?.fit === 100) return "#66bb6a";
    if (productDetails?.fit < 100 && productDetails?.fit > 67) return "#ffee58";
    if (productDetails?.fit <= 67 && productDetails?.fit > 33) return "#ffa726";

    return "#ef5350";
  }

  function setBackground() {
    // console.log(productDetails.fit);
    if (productDetails?.fit === 100) return "#e8f5e9";
    if (productDetails?.fit < 100 && productDetails?.fit > 40) return "#fffdf6";

    return "#fff9fa";
  }

  function handleSubmitButtonSuccessStyle() {
    setSubmitButtonBackground("success");
    setSubmitButtonText("Submitted");

    setTimeout(() => {
      setSubmitButtonBackground("secondary");
      setSubmitButtonText("Submit");
    }, 8000);
  }

  useEffect(() => {
    if (loadingSubmitApplication === false) {
      handleSubmitButtonSuccessStyle();

      dispatch(saveValue({ loadingSubmitApplication: null }))
    }
  }, [loadingSubmitApplication]);


  const handle = {
    downloadApplication: async (applicationId) => {
      await saveQuote();
      dispatch(
        getApplicationDownloadUrl(applicationId)
      ).then((res) => {
        const applicationURL = res?.payload?.data?.url
        if (applicationURL) {
          openInNewTab(applicationURL)
        }
      })
    },
    submitApplication: async (applicationId) => {
      dispatch(saveValue({ loadingSubmitApplication: true }));
      await saveQuote();
      await dispatch(submitApplication(applicationId)).unwrap();
    }
  }

  return (
    <>
      <Card
        alignItems="flex-start"
        style={{
          margin: "0 0 10px 0",
          // background: setBackground()
        }}
      // style={{ background: productDetails.score ? "#dcedc8" : "#fce4ec" }}
      >
        <Typography
          variant="body2"
          style={{
            // marginRight: "5px",
            // display: "flex",
            // justifyContent: "end",
            textAlign: "left",
            padding: "5px 16px",
            background: setHeaderBackground(),
          }}
        >
          <strong>
            Fit:
            <span>{productDetails?.criteria} criteria</span>
          </strong>
          {/* Fit: {productDetails.fit * 100}% */}
        </Typography>

        <CardContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "40px",
              marginBottom: "10px",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              style={{
                height: "40px",
                width: "40px",
                marginRight: "10px",
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // height: "40px",
                // marginBottom: "10px",
              }}
            >
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="subtitle"
                style={{ marginRight: "5px", fontSize: "16px" }}
              >
                {productDetails?.lender}
              </Typography>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="subtitle"
                style={{ fontSize: "14px" }}
              >
                {loanAmountValue > 150000 && loanAmountValue <= 250000 ? "Full Doc/ATO portal" : productDetails?.productName}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ margin: "10px 0" }} />
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="h5"
            style={{ marginRight: "5px" }}
          >
            <strong>{(isNaN(repaymentsValue) ? 0 : repaymentsValue)?.toLocaleString("en-US", dollarStringOptions)}/mth</strong>
          </Typography>
          <br></br>
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="body1"
            style={{ marginRight: "5px" }}
          >
            {((isNaN(repaymentsValue) ? 0 : repaymentsValue) / 4.33)?.toLocaleString("en-US", dollarStringOptions)}/wk
          </Typography>
          <br></br>
          <Divider style={{ margin: "10px 0" }} />
          <Typography
            sx={{ display: "inline" }}
            // component="span"
            variant="body2"
            style={{
              marginRight: "5px",

              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Total loan amount:</strong>
            {/* {!isEditMode ? ( */}
            <span>{(isNaN(loanAmountValue) ? 0 : loanAmountValue)?.toLocaleString("en-US", dollarStringOptions)}</span>
            {/* ) : (
              <TextField
                inputProps={{
                  style: {
                    padding: "0 3px",
                    fontSize: "13px",
                    textAlign: "right",
                  },
                }}
                size="small"
                value={loanAmount}
                style={{ width: "50%" }}
              />
            )} */}
          </Typography>
          <Typography
            sx={{ display: "inline" }}
            // component="span"
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Rate:</strong>
            {!isEditMode ? (
              <span>{rateValue}%</span>
            ) : (
              <TextField
                inputProps={{
                  style: {
                    padding: "0 3px",
                    fontSize: "13px",
                    textAlign: "right",
                  },
                }}
                size="small"
                onChange={(event) => {
                  // console.log("on change", event.target.value);

                  setRateValue(event.target.value);
                }}
                value={rateValue}
                style={{ width: "50%" }}
              />
            )}
          </Typography>
          {/* <br></br> */}

          {/* <br></br> */}
          <Typography
            sx={{ display: "inline" }}
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Brokerage:</strong>
            {!isEditMode ? (
              <span>{brokerageValue || 0}%</span>
            ) : (
              <TextField
                // type="number"
                inputProps={{
                  style: {
                    padding: "0 3px",
                    fontSize: "13px",
                    textAlign: "right",
                  },
                }}
                size="small"
                onChange={(event) => {
                  // console.log(event.target.value * 1);

                  if (event.target.value * 1 > productDetails?.brokerage?.max) return setBrokerageValue(productDetails.brokerage.max);
                  setBrokerageValue(event.target.value * 1);

                  // setBrokerage(0);
                }}
                value={brokerageValue}
                style={{ width: "50%" }}
              />
            )}
          </Typography>
          <Typography
            sx={{ display: "inline" }}
            variant="body2"
            color={productDetails?.score ? "#222222" : "aaaaaa"}
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {productDetails?.fees[0] && (
              <Stack direction="column" style={{ width: "100%" }}>
                {/* <Typography variant="subtitle">
                  <strong>Fees</strong>
                </Typography> */}
                {/* <ul style={{ marginLeft: "24px" }}> */}
                {productDetails?.fees?.map((fee) => {

                  if (fee.name === "Origination fee") {
                    return !isEditMode ? (
                      <Stack direction="row" style={{ justifyContent: "space-between", width: "100%" }}><strong>{fee.name}:</strong><span>${feesValue?.toFixed(2)}</span></Stack>
                    ) : (
                      <Stack direction="row" style={{ justifyContent: "space-between", width: "100%" }}><strong>{fee.name}:</strong>
                        <TextField
                          // type="number"
                          inputProps={{
                            style: {
                              padding: "0 3px",
                              fontSize: "13px",
                              textAlign: "right",
                            },
                          }}
                          size="small"
                          onChange={(event) => {
                            // console.log(event.target.value * 1);

                            if (event.target.value * 1 > fee.max) {
                              handleFees(fee.max)
                              return setFeesValue(fee.max);
                            }
                            setFeesValue(event.target.value * 1);

                            handleFees(event.target.value * 1)

                            // setBrokerage(0);
                          }}

                          value={feesValue}
                          style={{ width: "50%" }}
                        />
                      </Stack>
                    )
                  }

                  return < Typography variant="body2">
                    {/* <li> */}
                    <Stack direction="row" style={{ justifyContent: "space-between", width: "100%" }}><strong>{fee.name}:</strong> <span>${fee.value.toFixed(2)}</span></Stack>
                    {/* </li> */}
                  </Typography>
                })

                }



                {/* </ul> */}
              </Stack>
            )}
            {/* <span>
              ${productDetails?.fees.feesTotal.toLocaleString("en-US")}
            </span> */}
          </Typography>
          {/* <Typography variant="body2">Fit: {productDetails.fit}%</Typography> */}
          {/* <Typography
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Fit:</strong>{" "}
            <span>{productDetails.criteria} criteria</span>
           
          </Typography> */}
          {/* <Typography
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Completion:</strong>{" "}
          </Typography> */}
        </CardContent>
        <Divider style={{ margin: "0 0 10px" }} />
        <Stack spacing={1} direction="row" justifyContent="center">
          <LoadingButton
            color="secondary"
            endIcon={<FileCopyOutlinedIcon />}
            // loading={loadingDownloadUrl}
            loadingPosition="end"
            variant="outlined"
            size="small"
            disabled={
              !application?.entities?.[0] || !application?.customers?.[0]
            }
            onClick={() => handle.downloadApplication(application._id)}
          // href={url}
          >
            Download
          </LoadingButton>

          <LoadingButton
            color={submitButtonBackground}
            endIcon={<SendOutlinedIcon />}
            loading={loadingSubmitApplication}
            loadingPosition="end"
            variant="contained"
            size="small"
            disabled={
              !isApplicationComplete
            }
            onClick={() => handle.submitApplication(application._id)}
          >
            {submitButtonText}
          </LoadingButton>
        </Stack>
        <Divider style={{ margin: "10px 0 0" }} />
        <CardActions
          disableSpacing
          style={{
            justifyContent: "space-between",
            padding: "3px 16px",
          }}
        >
          <Button
            size="small"
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            // variant="text"
            // color="#424242"
            style={{ padding: 0, minWidth: "auto" }}
          >
            {expanded ? "close" : "Show more"}
          </Button>
          <Button
            size="small"
            expand={expanded}
            onClick={() => {
              if (isEditMode) saveQuote();
              setIsEditMode(!isEditMode);
            }}
            aria-expanded={expanded}
            aria-label="show more"
            // variant="text"
            // color="#424242"
            style={{ padding: 0, minWidth: "auto" }}
          >
            {isEditMode ? "Done" : "Edit"}
          </Button>
        </CardActions>
        {/* <Divider style={{ margin: "0" }} /> */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ paddingTop: "5px" }}>
            {/* {productDetails?.loading.loadingList[0] && (
              <>
                <Typography variant="subtitle">
                  <strong>Extras</strong>
                </Typography>
               
                {productDetails?.loading.loadingList
                  ?.sort((x, y) => {
                    if (x.result && x.result !== y.result) return -1;
                    if (x.result === undefined && y.result) return 1;
                    if (y.result === undefined && !x.result) return 1;
                    if (x.result === y.result) return 0;
                    if (!y.result) return -1;
                  })
                  .map((load) => (
                    <Typography
                      variant="body2"
                      // color={handleCriteriaColor(load.result)}
                      style={extrasStyle(load.result)}
                    >
                     
                      {load.name}: {load.value}%
                    </Typography>
                  ))}
               
                <Divider style={{ margin: "10px" }} />
              </>
            )} */}

            <Typography variant="subtitle">
              <strong>Criteria</strong>
            </Typography>
            {/* <ul
              style={{
                marginLeft: "24px",
              }}
            > */}
            {productDetails?.criteriaResults
              ?.sort((x, y) => {
                if (x.result && x.result !== y.result) return -1;
                if (x.result === undefined && y.result) return 1;
                if (y.result === undefined && !x.result) return 1;
                if (x.result === y.result) return 0;
                if (!y.result) return -1;
              }) // sort from true to false
              .map((criteria) => (
                // <li style={{ color: handleCriteriaColor(criteria.result) }}>
                <Typography
                  variant="body2"
                  color={handleCriteriaColor(criteria.result)}
                >
                  {criteria.label}
                </Typography>
                // </li>
              ))}

            {/* </ul> */}
          </CardContent>
        </Collapse>
      </Card>

      {/* <Divider
          // variant="inset"
          component="li"
        /> */}
      {/* </List> */}
    </>
  );
}