/* eslint-disable import/no-anonymous-default-export */
import criteria from "src/utils/criteria";
import { livingArrangementsTypes, typeOfSaleList } from "src/constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes

const {
  livingArrangements,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetLessThanYearly,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  gstRegistrationGreaterThan,
  timeInBusinessGreaterThan,
  timeInBusinessLessThan,
  creditScoreGreaterThan,
  brokerageAmount
} = criteria;

const {
  DEALER_SALE,
  PRIVATE_SALE,
  MID_TERM_REFINANCE,
  BALLOON_RESIDUAL_REFINANCE,
  SALE_BUYBACK_EQUITY
} = typeOfSaleList

export default {
  date: "28 Mar 2025",
  lender: "Group & General Finance",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FG%26G%2Blogo.gif",
  fees: [
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Document fee",
      value: 649,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 300001)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),],
    // },
    {
      name: "Private sale",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Brokerage 5%",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageAmount(5, 5.9)],
    },
    {
      name: "Brokerage 6%",
      value: 2,
      valueType: "percentage",
      criteria: [brokerageAmount(6, 6.1)],
    },
    {
      name: "Mid term refinance",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale("Mid-term refinance")],
    },
    {
      name: "Sale & buyback / Equity",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale("Sale & buyback / Equity")],
    },
    {
      name: "ABN <12 months",
      value: 2,
      valueType: "percentage",
      criteria: [timeInBusinessLessThan(25)],
    },
    // {
    //   name: "Loan amount >$150k",
    //   value: 0.5,
    //   valueType: "percentage",
    //   criteria: [loanAmountGreaterThan(150000)],
    // },
  ],
  info: [
    "Bankstatements required for all applications",
    "100k - Max lend for non-property",
    "Income: Brokerage: 4% included, Max of 6% with 1 % rate load",
    "Additional rate loads for Adverse and scores <650 (Express) and <600 (light Doc)",
    "ATO arrears - may have a rate load (GGF discretion)",
    "Hire / Rental / UBER / Courier may have a rate load - 1-4% (GGF discretion)",
    "Max term 60 mths",
    "Max balloons/residuals 30% (restricted to <24mths age at start)"
  ],
  productTiers: [
    {
      name: "Express",
      rate: 6.79,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(5),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(651),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([DEALER_SALE])
      ],
    },
    {
      name: "Express",
      rate: 7.29,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(5),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(651),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([DEALER_SALE])
      ],
    },



    {
      name: "Light doc",
      rate: 7.09,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 160000),
        ageOfAssetLessThanYearly(2),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 7.69,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 160000),
        ageOfAssetLessThanYearly(6),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 8.49,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 160000),
        ageOfAssetLessThanYearly(10),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 9.39,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 160000),
        ageOfAssetLessThanYearly(14),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 10.29,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 160000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },



    {
      name: "Light doc",
      rate: 7.49,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 350000),
        ageOfAssetLessThanYearly(2),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 8.49,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 350000),
        ageOfAssetLessThanYearly(6),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 8.89,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 350000),
        ageOfAssetLessThanYearly(10),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 9.79,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 350000),
        ageOfAssetLessThanYearly(14),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 10.69,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 350000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },




    {
      name: "Light doc",
      rate: 9.09,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(2),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 9.69,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(6),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 10.49,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(10),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 11.39,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(14),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 12.29,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },




    {
      name: "Light doc",
      rate: 9.49,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(2),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 10.09,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(6),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 10.89,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(10),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 11.79,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(14),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Light doc",
      rate: 12.69,
      productType: "Light doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },












    {
      name: "Express - Residual",
      rate: 7.79,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 200000),
        assetType([
          "Cars, Utes & Vans",
          "Trucks & Equipment"
        ]),
        ageOfAssetAtEndLessThan(12),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          BALLOON_RESIDUAL_REFINANCE,
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 8.79,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        assetType([
          "Cars, Utes & Vans",
          "Trucks & Equipment"
        ]),
        ageOfAssetAtEndLessThan(12),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          BALLOON_RESIDUAL_REFINANCE,
        ])
      ],
    },
















    {
      name: "Expess - Replacement",
      rate: 7.09,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 350000),
        ageOfAssetLessThanYearly(2),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 7.69,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(6),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 8.49,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(10),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 9.39,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(14),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 10.29,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },



    {
      name: "Expess - Replacement",
      rate: 7.49,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(2),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 8.49,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(6),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 8.89,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(10),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 9.79,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        ageOfAssetLessThanYearly(14),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 10.69,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([PROPERTY_OWNER]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },




    {
      name: "Expess - Replacement",
      rate: 9.09,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(2),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 9.69,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(6),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 10.49,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(10),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 11.39,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(14),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 12.29,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Cars, Utes & Vans"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },




    {
      name: "Expess - Replacement",
      rate: 9.49,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(2),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 10.09,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(6),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 10.89,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(10),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 11.79,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        ageOfAssetLessThanYearly(14),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    },
    {
      name: "Expess - Replacement",
      rate: 12.69,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 100000),
        // ageOfAssetLessThanYearly(15),
        assetType(["Trucks & Equipment"]),
        ageOfAssetAtEndLessThan(30),
        timeInBusinessGreaterThan(35),
        gstRegistrationGreaterThan(35),
        creditScoreGreaterThan(601),
        livingArrangements([
          RENTING,
          BOARDING,
          LIVING_WITH_PARENTS
        ]),
        typeOfSale([
          DEALER_SALE,
          PRIVATE_SALE,
          MID_TERM_REFINANCE,
          BALLOON_RESIDUAL_REFINANCE,
          SALE_BUYBACK_EQUITY
        ])
      ],
    }
  ],
};