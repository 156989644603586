import { useEffect, useState } from "react";
import _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Hidden, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  saveAsset,
  startApplication,
  getApplication,
  updateAsset,
  updateLoanDetails,
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  saveLoanDetails,
  userDetailsSelector,
  createCustomer,
  getApplicationDownloadUrl,
  submitApplication,
  clearState,
  entitySelector,
  customersSelector,
} from "../store/slices/applicationFormSlice";

import products from "../products";

import getBalloon from "src/utils/getBalloon";
import calculateRepayments from "src/utils/calculateRepayments";
import {
  evaluateCriteria,
  calculateLoading,
  calculateFees,
  calculateExtras,
} from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";

import ProductSelectorOrderBy from "./ProductSelectorOrderBy";
import ProductSelectorFilterBy from "./ProductSelectorFilterBy";
import Product from "./Product";

// console.log("products", products);

export default function ProductSelector({ isApplicationComplete }) {
  const dispatch = useDispatch();

  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  let { application } = useSelector(userDetailsSelector);
  // console.log("application", application);

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    privateSale,
    make = "",
    model = "",
    description = "",
    typeOfSale,
    // documentFee: 320,
  } = useSelector(assetSelector);

  const {
    // applicationFee = 0,
    balloon = 0,
    term = 0,
    // repayments = 0,
    // loanAmount = 0,
    brokerage = 0,
    rate = 0,
    deposit = 0,
    fees
  } = useSelector(loanDetailsSelector);

  const [canSubmit, setCanSubmit] = useState(true);

  const [productsMap, setProductsMap] = useState([]);
  // const [loanAmount, setLoanAmount] = useState(0);
  const [orderByParameters, setOrderByParameters] = useState([
    // ["fit", "desc"],
    // ["repayments", "asc"],
  ]);
  const [orderByParametersList, setOrderByParametersList] = useState([
    ["qualification", "repayments"],
    ["asc", "asc"],
  ]);

  const [filterByParameters, setFilterByParameters] = useState([
    "Azora Asset Finance",
    "Selfco",
    "Angle Finance",
    "Group & General Finance",
    "Quest Finance"
  ]);

  const BALLOON = balloon / 100 * assetValue

  const handleLoanAmount = (fees, brokerage) => {
    // console.log(assetValue, fees, deposit, brokerage);
    // console.log(assetValue + fees - deposit);

    const dep = (deposit / 100 * assetValue)
    const brok = (assetValue - dep) * (brokerage / 100)

    // console.log({ dep }, { brok })
    // console.log(assetValue - dep + brok + fees)

    return assetValue - dep - BALLOON + brok + fees

  };

  function evaluateProducts() {
    // const customerProfile = customers?.map(
    //   (customer) => customer.customerProfile
    // );
    const customerProfile = customers?.[0]?.customerProfile

    const creditProfile = customers?.map((customer) => customer.creditProfile);

    const productList = products?.map((product) => {
      return product?.productTiers?.map((tier) => {
        // console.log(product.brokerage);
        const defaultBrokerage = calculateExtras(product.brokerage || 0, {
          asset,
          loanDetails,
          entity,
        })[0];

        const customBrokerage = brokerage;

        // console.log("brokerage", brokerage);
        // console.log("defaultBrokerage", defaultBrokerage);
        // console.log("assetValue", assetValue);
        // console.log("deposit", deposit);
        // console.log("customBrokerage", customBrokerage);

        // console.log(
        //   "brokerage",
        //   ((customBrokerage || defaultBrokerage) / 100) * (assetValue - deposit)
        // );

        if (customBrokerage && defaultBrokerage?.value) {
          defaultBrokerage.value = customBrokerage
        }

        // console.log({ fees: loanDetails.fees })

        const FEES = fees?.[0] ? fees : product?.fees

        const feesList = calculateFees(FEES, {
          asset,
          loanDetails,
          entity,
          customerProfile,
          creditProfile,
        });

        const feesTotal = feesList.reduce(
          (accum, item) => item.value + accum,
          0
        );

        // console.log("feesTotal", feesList);
        // console.log("feesTotal", feesTotal);

        const loanAmount = handleLoanAmount(
          feesTotal,
          defaultBrokerage?.value || 0
          // fees
        );

        // console.log("loanAmount", loanAmount);

        // console.log("lender", product.lender);
        // console.log("product.loading", product.loading);

        const loadingList = calculateLoading(product.loading, {
          asset,
          loanDetails: {
            ...loanDetails,
            ...{
              loanAmount: loanAmount,
              brokerageRate: defaultBrokerage?.value,
            },
          },
          entity,
          customerProfile,
        });

        const loadingTotal = loadingList.reduce((accum, item) => {
          // console.log(item.name, item.result);
          return item.result === true ? item.value + accum : accum;
        }, 0);

        // const loadingTotal = 0;

        // console.log("LOADING: ", loadingList);
        // console.log("LOADING total: ", loadingTotal);
        // console.log("rate: ", tier.rate);
        // console.log("rate + loading: ", (tier.rate + loading).toFixed(2));
        // console.log(loanAmount, balloon, term, tier.rate);

        const quoteDetails = calculateQuote(
          loanAmount,
          BALLOON,
          term,
          tier.rate
        );

        // console.log("quoteDetails ==>>", quoteDetails);

        const LOAN_DETAILS = {
          ...loanDetails,
          ...{
            loanAmount: loanAmount,
            repayments: quoteDetails.repayments,
          },
        };

        // console.log(LOAN_DETAILS);
        // console.log("tier.productCriteria", tier)

        // console.log("loading", loading);

        const productDetails = {
          lender: product.lender,
          productName: tier.name,
          name: tier.name,
          rate: (tier.rate + loadingTotal).toFixed(2),
        };

        // console.log("productDetails", productDetails);

        const criteriaResults = evaluateCriteria(tier.criteria, {
          asset,
          loanDetails: LOAN_DETAILS,
          entity,
          customerProfile,
          creditProfile
        }).filter((result) => {
          if (!result?.criteria) return result;
          if (!result?.conditional) return result;

          if (
            result.items.some(
              (item) => result?.primary === item.attribute && item.result
            )
          )
            return result;

          // if (result.items.some((item) => item.result)) return result;
        });

        // console.log("criteriaResults", criteriaResults);

        const overallResult = criteriaResults.reduce((count, item) => {
          // console.log(item);
          // if (item?.result?.[0]) {
          //   const compoundResult = item.result.reduce((boolean, criteria) => {
          //     return criteria.result ? boolean : false;
          //   }, true);

          //   console.log("compoundResult", compoundResult)

          //   return compoundResult ? ++count : count;
          // }

          return item?.result ? ++count : count;
        }, 0);

        // console.log("overallResult", overallResult);
        // console.log("feesList", feesList);

        return _.merge(productDetails, {
          loanAmount: 1 * loanAmount,
          repayments: quoteDetails?.repayments,
          brokerage: defaultBrokerage || 0,
          fees: feesList,
          loading: { loadingList, loadingTotal },
          criteriaResults,
          fit: (overallResult / criteriaResults.length) * 100,
          criteria: `${overallResult}/${criteriaResults.length}`,
          qualification: criteriaResults.length - overallResult,
          term,
          assetValue,
          deposit: (deposit / 100 * assetValue),
        });
      });
    });

    // console.log(orderByParameters);

    // console.log("filterByParameters", filterByParameters);

    // const list = _.chain(productList)
    //   .flatten()
    //   .filter((product) => filterByParameters.includes(product?.lender))
    //   .filter((product) => {
    //     const assetTypeCriteria = product.criteriaResults.find((result) => {
    //       return result?.attribute === "assetType";
    //     });

    //     return (
    //       assetTypeCriteria?.result === true ||
    //       assetTypeCriteria?.result === undefined
    //     );
    //   })
    //   .filter((product) => {
    //     const ageOfAssetCriteria = product.criteriaResults.find((result) => {
    //       return result?.attribute === "ageOfAsset";
    //     });

    //     return (
    //       ageOfAssetCriteria?.result === true ||
    //       ageOfAssetCriteria?.result === undefined
    //     );
    //   })
    //   .filter((product) => {
    //     const ageOfAssetCriteria = product.criteriaResults.find((result) => {
    //       return result?.attribute === "loanAmount";
    //     });

    //     return (
    //       ageOfAssetCriteria?.result === true ||
    //       ageOfAssetCriteria?.result === undefined
    //     );
    //   })
    //   .filter((product) => {
    //     const ageOfAssetCriteria = product.criteriaResults.find((result) => {
    //       return result?.attribute === "livingArrangements";
    //     });

    //     console.log({ ageOfAssetCriteria })

    //     return (
    //       ageOfAssetCriteria?.result === true ||
    //       ageOfAssetCriteria?.result === undefined
    //     );
    //   })
    //   .orderBy(...orderByParametersList)
    //   .value();


    const list = productList
      .flat()
      .filter((product) => filterByParameters?.includes(product?.lender))
    // console.log("list", list);
    setProductsMap([list[0]]);
  }

  const calculateQuote = (loanAmount, balloon, term, rate) => {
    const monthlyRate = getMonthlyRate(rate);
    const rpmts = calculateRepayments(monthlyRate, term, -loanAmount, balloon, 1);
    const roundedRepayments = (Math.round(rpmts * 100) / 100).toFixed(2);

    return {
      repayments: roundedRepayments * 1,
    };
  };

  // async function handleMinimumRequirements() {
  //   const results = customers?.map(async (customer) => {
  //     console.log({ customer });
  //     return await Promise.all([
  //       handleAssetsRequired(customer),
  //       handleLiabilitiesRequired(customer),
  //       handlePhoneNumberRequired(customer),
  //       handleEmailAddressRequied(customer),
  //       handleDriversLicenceRequired(customer),
  //       handleDOBRequired(customer),
  //       handleAddressesRequired(customer),
  //       handleEntityRequired(entity)
  //     ]);
  //   });
  //   console.log({ results })

  //   let resultsArray = await Promise.all(results || []);

  //   // .includes(false || undefined);
  //   console.log("result", resultsArray);
  //   if (!resultsArray[0]) resultsArray.push(false)
  //   // console.log("result", resultsArray.flat().includes(false));
  //   return resultsArray.flat().includes(false);
  // }

  // function handleEntityRequired(entity) {
  //   return entity?._id
  // }

  // function handleAssetsRequired(customer) {
  //   // console.log(
  //   //   customer.firstName,
  //   //   "assets",
  //   //   customer?.customerAssets?.items
  //   //     ?.map((asset) => !!asset.value)
  //   //     .includes(true)
  //   // );
  //   return customer?.customerAssets?.items
  //     ?.map((asset) => !!asset.value)
  //     .includes(true);
  // }

  // function handleLiabilitiesRequired(customer) {
  //   // console.log(
  //   //   customer.firstName,
  //   //   "liabilities",
  //   //   customer?.liabilities?.items
  //   //     ?.map((liability) => !!liability.value)
  //   //     .includes(true)
  //   // );
  //   return customer?.liabilities?.items
  //     ?.map((liability) => !!liability.value)
  //     .includes(true);
  // }

  // function handlePhoneNumberRequired(customer) {
  //   console.log(customer.firstName, "phone", !!customer?.phoneNumbers?.[0]);
  //   return !!customer?.phoneNumbers?.[0];
  // }

  // function handleEmailAddressRequied(customer) {
  //   console.log(customer.firstName, "email", !!customer?.emails?.[0]);
  //   console.log(!!customer?.emails?.[0])
  //   return !!customer?.emails?.[0];
  // }

  // function handleDriversLicenceRequired(customer) {
  //   console.log(
  //     customer.firstName,
  //     "DL",
  //     !!customer.IDNumber || !!customer?.documents?.find((doc) => {
  //       return doc.details?.licence_number;
  //     })?.details?.licence_number
  //   );
  //   return !!customer.IDNumber || !!customer?.documents?.find((doc) => {
  //     return doc.details?.licence_number;
  //   })?.details?.licence_number
  // }

  // function handleDOBRequired(customer) {
  //   // console.log(customer.firstName, "dob", !!customer?.dateOfBirth);
  //   return !!customer?.dateOfBirth;
  // }

  // function handleAddressesRequired(customer) {
  //   // console.log(customer.firstName, "address", !!customer?.addresses?.[0]);
  //   return !!customer?.addresses?.[0];
  // }

  // function handleOrderBy(event) {
  //   // console.log(event.target.value);

  //   setOrderByParameters(event.target.value);
  //   const params = event.target.value.map((i) => {
  //     return i[0];
  //   });
  //   const order = event.target.value.map((i) => {
  //     return i[1];
  //   });
  //   // console.log([params, order]);
  //   setOrderByParametersList([params, order]);
  // }

  function handleFilterBy(event) {
    // console.log("event.target.value");
    // console.log(event.target.value);

    setFilterByParameters(event.target.value);
  }

  useEffect(() => {
    // if ((assetValue, assetType, term, ageOfAsset, privateSale)) {
    // console.log("evaluate");
    evaluateProducts();
    // console.log("productsMap", productsMap);
    // }
    // setProductsMap(productsList);
  }, [
    rate,
    brokerage,
    assetValue,
    deposit,
    assetType,
    privateSale,
    ageOfAsset,
    balloon,
    term,
    customers,
    orderByParameters,
    filterByParameters,
    typeOfSale,
    fees
  ]);

  // useEffect(() => {
  //   (async () => {
  //     const canSubmitResult = await handleMinimumRequirements()
  //     console.log({ canSubmitResult })
  //     setCanSubmit(canSubmitResult);
  //   })();
  // }, [customers]);

  useEffect(() => {
    console.log({ isApplicationComplete })
  }, [isApplicationComplete]);

  return (
    <>
      {/* <Stack direction="row"> */}
      {/* <ProductSelectorFilterBy
        handleFilterBy={handleFilterBy}
        value={filterByParameters}
      />
      <ProductSelectorOrderBy
        handleOrderBy={handleOrderBy}
        value={orderByParameters}
      /> */}

      {/* </Stack> */}
      {productsMap?.map((productDetails) => (
        <Product productDetails={productDetails} isApplicationComplete={isApplicationComplete} />
      ))}
    </>
  );
}