import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

import {
  addPhoneNumber,
  saveCustomer,
} from "../store/slices/applicationFormSlice";
import regex from "../utils/regex";

export default function PhoneNumber({ phoneNumber, customerId }) {
  const dispatch = useDispatch();
  const [number, setNumber] = useState();

  const handleAddPhoneNumber = () => {
    let isValid = true
    isValid = regex.phoneNumber.test(number)

    if (number && phoneNumber?.number !== number && isValid) {
        dispatch(
          addPhoneNumber({
            phoneNumberId: phoneNumber?._id,
            customerId,
            number: number.replace(/\s+/g, ''),
          })
        );
      }
    };

    const handlePhoneNumberValue = (event) => {
      const value = event.target?.value
      value === "" ? setNumber("") : setNumber(value);
    };

    useEffect(() => {
      setNumber(phoneNumber?.number);
    }, []);

    return (
      <>
        <TextField
          error={!number}
          helperText={!number ? "Required" : ""}
          id="outlined-basic"
          type="text"
          name="applicant_phoneNumber"
          label="Mobile number"
          variant="outlined"
          value={number || ""}
          onChange={handlePhoneNumberValue}
          onBlur={() => handleAddPhoneNumber()}
          inputProps={{
            autoComplete: "none"
          }}
        />
      </>
    );
  }
